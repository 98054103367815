/* new common css */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700;800;900&family=Roboto:wght@300;400;500;700;900&display=swap");

.App {
  text-align: center;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
}

.App {
  flex: 1;
}

/* end common css */
.disabled {
  opacity: 0.4;
}
.btn {
  border-radius: 0;
}
.video-search-section {
  height: calc(100vh - 64px);
}
.video-search-section .search-form-container {
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
}
.video-search-section .form-block {
  /* max-width: 1000px; */
  width: 100%;
  /* border-bottom: 1px solid #e2dcdc; */
}
.video-search-section .form-control {
  max-width: 337px;
  width: 100%;
  height: 56px;
}
.video-search-section .bottom-content {
  /* border-top: 1px solid #AAAAAA; */
  padding: 20px 10px;
}
.export-button {
  width: 35px;
}
.blink-text {
  animation: blinker 2s linear infinite;
  color: #af2b2b;
}
.css-13cymwt-control {
  width: 245px;
}
.css-hlgwow {
  width: 200px;
}
/* .custom-modal .modal-content {
    background-color: #ffffff;
    opacity: 1; 
}

.modal-backdrop {
    opacity: 0.7; 
} */

.modal-backdrop.show {
  opacity: 0 !important; /* Set your desired opacity */
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.btn.btn-outline-dark {
  border: 1px solid #1d3c6e;
  color: #1d3c6e;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.video-search-section .form-select {
  height: 56px;
  color: #919191;
}
.form-select option {
  color: #333;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  content: url("../public/images/arrow-right.svg"); /* Replace with the path to your image */
}
.btn.btn-outline-dark:hover,
.btn.btn-outline-dark:focus,
.btn.btn-outline-dark:active {
  background-color: transparent;
  color: #1d3c6e;
}
.breadcrumb-item {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #3165ae;
}
.breadcrumb-item a {
  text-decoration: none;
  color: #919191;
  font-family: Roboto;
}
.section-title {
  color: #333;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: start;
}
.header-navbar {
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: fixed;
  width: 100%;
  z-index: 9999;
}
.profile-name-text {
  color: #223345;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 128.571% */
}

.profile-sub-text {
  color: #828282;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 180% */
}

.header-navbar {
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: fixed;
  width: 100%;
  z-index: 9999;
}

.profile-dropdown .dropdown-toggle::after {
  content: "";
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  width: 24px;
  height: 24px;
  background-image: url("../public/images/chevron-down.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border: none;
}

.app-container {
  height: 100vh;
}
.main-content {
  padding-top: 64px;
  height: 100%;
}
.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal {
  z-index: 1050; /* or higher */
}

.modal-backdrop {
  z-index: 1040; /* should be lower than modal */
}
.dot {
  width: 3px;
  height: 3px;
  background-color: #000;
  border-radius: 50%;
  margin: 5px; /* Add vertical spacing */
  position: relative;
}

/* Styles for the dropdown container */
.dropdown-container {
  position: relative;
}

/* Styles for the dropdown content */
.dropdown-content {
  display: block;
  position: absolute;
  top: 30px; /* Adjust this value as needed for positioning */
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
}
.dropdown-item:active {
  color: #000;
  background-color: transparent;
}
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  width: 65%;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  font-size: 35px;
}

.sidebar-toggle-button {
  display: none;
  margin: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .sidebar-toggle-button {
    display: block;
  }
}

@media (max-width: 992px) {
  .bg-dark.close {
    left: -256px;
  }
}

.sidebar {
  position: fixed;
  top: 64px;
  left: 0;
  width: 256px;
  background-color: #eee;
  color: #fff;
  transition: left 0.3s ease-in-out;
  z-index: 1000;
  padding-top: 20px;
  height: calc(100vh - 64px);
  overflow-y: auto;
}

.modal-block {
  z-index: 9999;
}

.toggle-button {
  position: relative;
  color: #000;
  cursor: pointer;
  font-size: 1.5rem;
  border: none;
  background: none;
}

.toggle-button img {
  max-width: 24px;
  width: 100%;
}

.sidebar-content {
  padding-bottom: 20px;
}

.sidebar::-webkit-scrollbar {
  width: 8px;
}

.sidebar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border-radius: 4px;
  cursor: pointer;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #8f8f8f;
}

.sidebar::-webkit-scrollbar-corner {
  background-color: transparent;
}

.btn.btn-primary {
  background-color: #2e65ae;
  border: none;
}

.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus {
  background-color: #2e65ae;
}

.logo-wrapper {
  position: absolute;
  top: 20px;
  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  justify-content: center;
}

.logo-wrapper img {
  max-width: 200px;
  display: inline-block;
}

.text-primary {
  color: #2e65ae !important;
}
.search-list-table th,
.transcript-list-table th,
.prompts-table th,
.saved-list-table th,
.scribe-contents-table th {
  background: #094fa3 !important;
  color: #e9ecef !important;
}
.transcript-list-table {
  text-align: left !important;
}
.prompts-table th,
.transcript-list-table th {
  text-wrap: nowrap;
}
.transcript-list-table td {
  background: transparent;
}
.accordion-menu-wrapper .nav-link {
  border-bottom: 1px solid #eee;
  text-align: left;
}
.accordion-menu-wrapper .nav-link:last-child {
  border: none;
}
.accordion-menu-wrapper a.nav-link {
  color: #333;
}
.accordion-menu-wrapper .nav-link.active {
  color: #3165ae;
  font-weight: 500;
}
.delete-link {
  text-decoration: none;
  color: #094fa3;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.032px;
}
.footer {
  background-color: #f8f9fa;
  position: fixed;
  bottom: 0;
  width: calc(100% - 256px);
  text-align: center;
  color: #333;
  right: 0;
  transition: left 0.3s ease-in-out;
}

.footer.expanded {
  width: 100%;
}

.breadcrumb-item a {
  color: #2e65ae;
}

.breadcrumb-item.active {
  font-weight: 600;
}

.table-responsive::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

.table-responsive::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border-radius: 4px;
  cursor: pointer;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: #8f8f8f;
}

.table-responsive::-webkit-scrollbar-corner {
  background-color: transparent;
}

.profile-dropdown {
  background: none;
  border: none;
}

.profile-toggle {
  color: #333;
  text-decoration: none;
  padding: 0;
  border: none;
  background: none;
}

.form-column {
  margin-bottom: 20px;
}

/* .pagination-wrapper .active>.page-link,
.page-link.active {
  background-color: #2e65ae;
  border-color: #2e65ae;
  color: #fff;
} */

.form-label {
  display: inline-block;
  width: 200px;
  text-align: left;
  margin-right: 10px;
  margin-bottom: 0;
  font-weight: 600;
}

/* .pagination-wrapper .page-link {
  color: #2e65ae;
} */

.pagination-wrapper .active > .page-link,
.page-link.active {
  background-color: #2e65ae;
  border-color: #2e65ae;
  color: #fff;
}
.pagination-wrapper .page-link {
  border: none;
  color: #121212;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.pagination-wrapper .page-item {
  margin: auto 0;
}

.pagination-wrapper .active > .page-link,
.page-link.active {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 200px;
  background: #3165ae;
}

.pagination-wrapper .disabled > .page-link,
.page-link.disabled {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 200px;
}

.pagination-wrapper .page-link:focus {
  background-color: transparent;
  box-shadow: none;
}
.mobile-view {
  flex-direction: column;
}

.main-content {
  flex: 1;
  padding-left: 0px;
  transition: margin-left 0.3s;
}

/* .sidebar-open.main-content {
  margin-left: 256px;
  width: calc(100% - 256px);
}

.sidebar {
  width: 256px;
  background-color: #f2f2f2;
  transition: transform 0.3s;
  transform: translateX(-256px);
}

.sidebar-open.sidebar {
  transform: translateX(0);
} */
.panel-trigger {
  border-top: 1px solid #aaaaaa;
}
.sidebar-bottum {
  text-align: right;
  margin-top: auto;
}
.dot-image {
  text-align: center;
  margin-bottom: 10px;
}
.sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 20px;
}

.sidebar-open.main-content {
  margin-left: 256px;
  width: calc(100% - 256px);
}

.sidebar-close.main-content {
  margin-left: 73px;
  width: calc(100% - 73px);
}

.sidebar {
  min-width: 73px;
  max-width: 73px;
  background-color: #f2f2f2;
  transition: transform 0.3s;
}

.sidebar-open.sidebar {
  min-width: 256px;
  max-width: 256px;
}

.accordion-box.minimized .accordion-item {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.accordion-box.minimized .accordion-button {
  padding: 0;
}

.accordion-box.minimized .accordion-button::after {
  display: none;
}

@media (max-width: 768px) {
  /* .sidebar {
    position: fixed;
    z-index: 999;
    height: 100%;
    transform: translateX(-100%);
  }

  .sidebar-open .sidebar {
    transform: translateX(0);
  }

  .sidebar-open .main-content {
    margin-left: 0;
  } */

  .toggle-button {
    display: block;
  }
}

.btn-search {
  margin-left: 35%;
  margin-right: 3%;
}

.btn-delete {
  margin-left: 75%;
}

.btn-spinner {
  margin-left: 40%;
}

.bi-plus-circle {
  margin-left: 96%;
}

.svhs-title {
  margin-left: 28%;
}

/* start login css */

.login-bg-img {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  background-image: url("../public/images/welcome-img.png");
  background-position: center;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}
.form-select,
.form-control {
  min-height: 48px;
}
.login-block {
  padding-top: 50px;
  max-width: 480px;
  width: 100%;
  display: inline-block;
  margin: auto;
  text-align: center;
}

.title-text {
  font-family: "Roboto Slab", serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 40px;
}

.login-block .form-control {
  width: 100%;
  min-height: 56px;
  padding: 16px 12px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  gap: 10px;
}

.form-control::placeholder,
.form-select {
  color: #919191;
}

.btn.btn-primary {
  background-color: #1d3c6e;
}

.btn {
  padding: 12px 24px;
  border-radius: 8px;
  min-height: 48px;
}

.btn.btn-link {
  color: #1d3c6e;
}

.copyright-text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: right;
}

.login-bg-wrapper {
  min-height: 100vh;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* end login css */

.modal-90w {
  max-width: 100%;
}

.btn-primary:not(.btn-active):focus {
  background-color: #1d3c6e !important;
}
.btn-active {
  background-color: #2e65ae !important;
}
.scribe-contents-table {
  text-align: left;
}

.scribe-generated-content h1 {
  font-size: 32px;
}

.scribe-generated-content h2 {
  font-size: 28px;
}

.scribe-generated-content h3 {
  font-size: 24px;
}

.scribe-generated-content h4 {
  font-size: 20px;
}

.scribe-generated-content h5 {
  font-size: 18px;
}

.scribe-generated-content h6 {
  font-size: 16px;
}
.form-check-input:disabled {
  background-color: #ddd;
  border-color: #ddd;
  cursor: not-allowed;
}